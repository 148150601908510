.lc-module {
  &.lc-bikes {
    display: inherit;

    .lc-tab-panel {
      padding: 15px;

      @include font-small;
    }

    .lc-bike {
      display: flex;
      height: inherit;
      cursor: pointer;
      padding: 5px;

      .lc-bike-content {
        margin-left: 10px;

        .lc-bike-available {
          display: flex;
          align-items: center;
          padding: 10px 0 5px;

          .lc-loading {
            padding: 0;
            align-items: initial;

            img {
              width: 20px;
              padding: 0;
            }
          }

          .lc-available,
          .lc-capacity {
            display: flex;
            align-items: center;

            img {
              width: 25px;
            }
          }

          .lc-capacity {
            margin-left: 10px;

            img {
              width: 12px;
            }
          }
        }
      }
    }

    .lc-legend {
      display: flex;
      align-items: center;
      padding: 5px 0;

      .lc-image {
        display: flex;
      }

      .lc-name {
        margin-left: 15px;
      }
    }
  }
}
