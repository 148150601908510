@mixin override {
  .lc-map .lc-slider {
    .lc-before,
    .lc-after {
      background: $primary;
      color: #333;
      padding: 10px !important;
    }
    .lc-between {
      background-color: $primary;
      border: solid #333 2px;
    }
  }

  .lc-uilinedetails-infos {
    @include font-medium;
  }

  .lc-pdf-button {
    color: #333 !important;
  }
}
