.lc-modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(255, 255, 255, 1);
  transition: all 250ms ease-in-out;
  opacity: 0;
  overflow: hidden;

  &.lc-active {
    opacity: 1;
  }

  .lc-head {
    display: flex;
    align-items: center;
    padding: 0 15px;
    background: white;

    .lc-close {
      padding-right: 25px;
      margin-left: -10px;
      background: url(assetsPath("/assets/images/back.svg")) center center no-repeat;
      width: 20px;
      height: 20px;
    }
  }

  .lc-input {
    border: none;
    border-radius: 5px;
    flex: 1;
    font-size: 16px !important;
    outline: none;
    padding: 15px;
    font-family: $font;
  }

  .lc-autocomplete {
    width: 100%;
    left: 0;
    right: 0;
    max-height: calc(100vh - 60px);
    overflow: auto;

    .lc-item {
      padding: 10px;
      font-size: 0.95em;
      font-weight: 600;

      img {
        padding: 5px 7px;
        height: 20px !important;
        min-width: 45px !important;
        margin-right: 10px;
      }
    }

    .lc-line {
      padding: 7px 10px !important;
    }
  }
}
