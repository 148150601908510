.lc-disruptions {
  z-index: 999;

  .lc-disruptions-head {
    position: relative;
    padding: 10px 8px;
    cursor: pointer;
    border-left: 3px solid $error;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    &.with-only-delays {
      border-left-color: $warning;
    }

    @include font-small;

    &:before {
      content: url(assetsPath("/assets/images/disruptions/blocking.svg"));
      width: 15px;
      height: 15px;
      border: 1px solid $error;
      border-radius: 50%;
      margin-right: 8px;
    }

    .lc-arrow {
      position: absolute;
      right: 3px;
      top: 12px;

      img {
        width: 20px;
        transition: all ease-in 250ms;
      }

      .lc-closed {
        transform: rotate(-90deg);
      }
    }
  }
}

.lc-infobox {
  .lc-severity {
    white-space: nowrap;
    margin: 0;
    padding: 10px 10px 10px 5px;
  }
}
