.lc-calendar {
  &.active {
    position: absolute;
    z-index: 999;
    top: 0;
  }
}
.react-calendar {
  width: 350px;
  padding: 15px;
  max-width: 100%;
  background: rgb(241, 245, 245);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 10px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;

  @include mobile {
    width: 100%;
    left: 0;
    right: 0;
  }
}
.react-calendar > * {
  background-color: white;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  text-transform: capitalize;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  /* margin-bottom: 1em; */
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #767676;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #767676;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
  border-radius: 5px;
}
.react-calendar__tile--now {
  background: rgb(244, 244, 244);
  font-weight: 500;
  border-radius: 5px;
}
.react-calendar__tile--hasActive {
  background: $primary;
  color: white;
  border-radius: 5px;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: $primary;
}
.react-calendar__tile--active {
  background: $primary;
  color: white;
  border-radius: 5px;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: $primary;
  border-radius: 5px;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-calendar__navigation__label__labelText {
  color: rgb(51, 51, 51);
  text-transform: capitalize;
  font-weight: 600;
  font-size: 1.1em;
}

.react-calendar__navigation__prev2-button:hover {
  border-top-left-radius: 5px;
}
.react-calendar__navigation__next2-button:hover {
  border-top-right-radius: 5px;
}

.react-calendar__month-view__weekdays {
  background-color: rgb(250, 250, 250);
}

.lc-calendar-today-button {
  color: rgb(51, 51, 51);
  cursor: pointer;
  padding: 10px;
  font-size: 0.9em;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  background-color: rgb(250, 250, 250);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: center;
}

abbr {
  text-decoration: none;
}
